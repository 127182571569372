<div class="container">
    <form [formGroup]="dealerPriceForm">
        <nav aria-label="breadcrumb" style="margin-top: 10px;">
            <ol class="breadcrumb" >
                <li class="breadcrumb-item active" aria-current="page" >
                    <div class="breadcrumb-content">

                       <i class="fa fa-book breadcrumb-icon"></i>
                <p class="h5 breadcrumb-text">Dealer Details</p>
            </div>
            </li>                
            </ol>
          </nav>
          <div class="row">
            <div class="col">
                <label for="dealerId">DealerId</label>
                <select class="form-select" formControlName="dealerId" [ngClass]="{ 'is-invalid': submitted && f.dealerId.errors }" >
                    <option *ngFor="let dealer of dealers " [ngValue]="dealer.dealerId">
                      {{ dealer.dealerId }}
                    </option>
                  </select>
                <div *ngIf="submitted && f.dealerId.errors" class="invalid-feedback">
                    <div *ngIf="f.dealerId.errors.required">Dealer id is required</div>
                </div>               
             </div>  
             <div class="col">
                <label for="applicableOn">Applicable On Model Types</label>
                <select class="form-select" formControlName="applicableOn" [ngClass]="{ 'is-invalid': submitted && f.applicableOn.errors }" >
                    <option *ngFor="let appType of ['Both','New','Old'] " [ngValue]="appType">
                      {{ appType }}
                    </option>
                  </select>
                <div *ngIf="submitted && f.applicableOn.errors" class="invalid-feedback">
                    <div *ngIf="f.applicableOn.errors.required"> Applicable On is required</div>
                </div>               
             </div>  
             <div class="col">
                <label for="dealerName">Dealer Name</label>
                <input type="text" formControlName="dealerName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerName.errors }" />
             </div>
             <div class="col">
                <label for="dealerCity">Dealer City</label>
                <input type="text" formControlName="dealerCity" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerCity.errors }" />
                </div>      
                </div>
                <div class="form-group" style="margin-top:5px">
                    <button class="btn btn-primary" (click)="onSubmit()">
                        Search
                    </button><a>&nbsp;&nbsp;</a>
            </div>
            <nav aria-label="breadcrumb" >
                <ol class="breadcrumb" >
                    <li class="breadcrumb-item active" aria-current="page" >
                        <div class="breadcrumb-content">
                        <i class="fa fa-book breadcrumb-icon" style="margin-right: 10px; "></i>
                    <p class="h5 breadcrumb-text"> Upload New FIle</p>
                </div>
            </li>
                </ol>
              </nav>
                  <div class="form-row">
                <div class="form-group col">
                <label for="uploadFile">Upload File</label>
                <input type="file" formControlName="uploadFile" (change)="onFileChange($event)" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.uploadFile.errors }" />
                <div *ngIf="submitted && f.uploadFile.errors" class="invalid-feedback">
                    <div *ngIf="f.uploadFile.errors.required">File is required</div>
                </div>
        
            </div>
                <div class="form-group col">
                </div>
            </div>
            <nav aria-label="breadcrumb"  *ngIf="gridReady">
                <ol class="breadcrumb" >
                    <li class="breadcrumb-item active" aria-current="page" >
                        <div class="breadcrumb-content">
                            <i class="fa fa-book breadcrumb-icon" style="margin-right: 10px; "></i>
                        <p class="h5 breadcrumb-text">Model Configuration Data</p>
                    </div>
                    </li>
                </ol>
              </nav>
                <div class="row" *ngIf="gridReady">
                <div class="col">
            <ag-grid-angular #aggrid
            style="width: 100%; height: 600px;"
            [gridOptions] = "gridOptions"
            (gridReady)="onGridReady($event)"
            class="ag-theme-alpine">
        </ag-grid-angular>
        </div>
        </div>
        <div class="form-group" style="margin-top:5px">
            <button class="btn btn-primary" (click)="addRow()">
                Add New Row
            </button><a>&nbsp;&nbsp;</a>
            <button class="btn btn-primary" (click)="saveData()">
                Save
            </button><a>&nbsp;&nbsp;</a>
            <button class="btn btn-primary" (click)="exportToExcel()">
                Export to Excel
            </button><a>&nbsp;&nbsp;</a>
        </div>

        <nav aria-label="breadcrumb" >
            <ol class="breadcrumb" >
                <li class="breadcrumb-item active" aria-current="page" >
                    <div class="breadcrumb-content">
                    <i class="fa fa-book breadcrumb-icon" style="margin-right: 10px; "></i>
                <p class="h5 breadcrumb-text">Template Details</p>
            </div></li>
            </ol>
          </nav>
          <div class="row">
            <div class="col">
                <h4>Download Dealer Price List Template from  <a target="_self" href="../../../assets/dealerPriceList.xlsx" download="Dealer Pricelist.xlsx"><span>here</span></a>
                </h4>
             </div>  
            <div class="col">
                </div>  
                </div>
    </form>
</div>